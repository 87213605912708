<template>
  <div class="land-admin">
    <slot class="adTitle" name="adTitle"></slot>
    <div class="ad-bot">
      <div class="tabs" v-if="tabsShow">
        <div
          class="tab-items"
          :class="changeIndex == index ? 'active' : ''"
          v-for="(item, index) in tabList"
          :key="item.id"
          @click="changeTab(item.id, index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="tabs" v-if="tabsShow2">
        <div
          class="tab-items"
          :class="changeIndex2 == index ? 'active' : ''"
          v-for="(item, index) in tabList2"
          :key="index"
          @click="changeTab2(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="tabs" v-if="tabsShow3">
        <div
          class="tab-items"
          :class="changeIndex3 == index ? 'active' : ''"
          v-for="(item, index) in tabList3"
          :key="index"
          @click="changeTab3(item.name, item.id, index)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="personnel" v-if="personnelShow">
        <div class="per-class">
          <span>类型</span>
          <el-select
            v-model="personnelType"
            placeholder="请选择类型"
            class="personnel-select"
            :class="isFocusShow ? '' : 'active'"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option label="管理员" value="2"></el-option>
            <el-option label="员工" value="3"></el-option>
          </el-select>
        </div>
        <div class="name">
          <span>姓名</span>
          <el-input
            class="personnel-el-input"
            :class="isFocusShow ? '' : 'active'"
            v-model.trim="personnelName"
            autocomplete="off"
            placeholder="请输入姓名"
            maxlength="20"
          ></el-input>
        </div>
        <div class="searchBtn" @click="searchPersonnel">搜索</div>
      </div>
      <div class="personnel" v-if="linkmanShow">
        <div class="per-class">
          <span>类型</span>
          <el-select
            v-model="linkmanType"
            placeholder="请选择类型"
            class="personnel-select"
            :class="isFocusShow ? '' : 'active'"
          >
            <el-option label="全部" value="0"></el-option>
            <el-option label="供应商" value="1"></el-option>
            <el-option label="客户" value="2"></el-option>
          </el-select>
        </div>
        <div class="name">
          <span>名称</span>
          <el-input
            class="personnel-el-input"
            :class="isFocusShow ? '' : 'active'"
            v-model.trim="linkmanName"
            autocomplete="off"
            placeholder="请输入名称"
            maxlength="20"
          ></el-input>
        </div>
        <div class="searchBtn" @click="searchLinkman">搜索</div>
      </div>
      <div class="task-box" v-if="taskShow">
        <div class="per-class task-time">
          <span>任务时间</span>
          <el-date-picker
            class="my-date-picker"
            v-model="taskTime"
            :clearable="false"
            type="datetimerange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
        <div class="per-class">
          <span>任务状态</span>
          <el-select v-model="status" placeholder="选择任务状态">
            <el-option label="待执行" value="0"></el-option>
            <el-option label="执行中" value="1"></el-option>
            <el-option label="已完成" value="2"></el-option>
            <el-option label="已关闭" value="-1"></el-option>
          </el-select>
        </div>
        <div class="per-class">
          <span>负责人</span>
          <el-select
            v-model="person"
            placeholder="选择负责人"
            v-loadMore="loadMoreMemberData"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in memberList"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="searchBtn" @click="getTaskSearch">搜索</div>
      </div>
      <!-- 种子安全记录 -->
      <div class="task-box" v-if="seedShow">
        <div class="per-class task-time">
          <span>任务时间</span>

          <el-date-picker
            v-model="seedValue"
            type="date"
            placeholder="选择日期时间"
          >
          </el-date-picker>
        </div>
        <div class="per-class">
          <span>负责人</span>
          <el-select
            v-model="person"
            placeholder="选择负责人"
            v-loadMore="loadMoreUserNameData"
          >
            <el-option
              :label="item.user_name"
              :value="item.mid"
              v-for="item in userNameList"
              :key="item.mid"
            ></el-option>
          </el-select>
        </div>
        <div class="searchBtn" @click="getSeedSearch">搜索</div>
      </div>
      <!-- 流程配置 -->
      <div class="process-box" v-if="processShow">
        <div class="per-class">
          <span>选择作物分类</span>
          <el-select
            v-model="person"
            placeholder="请选择作物分类"
            @change="changeCropClass(1, $event)"
            v-loadMore="loadMoreCropClassData"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in cropClassList"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="per-class">
          <span>选择作物</span>
          <el-select
            v-model="land"
            placeholder="请选择作物"
            @change="changeCropItems"
            v-loadMore="loadMoreCropData"
          >
            <el-option
              :label="item.title"
              :value="item.id"
              v-for="item in cropItemsList"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="r-add" @click="addLands" v-if="addShow">
        <img src="../assets/image/land/addland.png" class="add-icons" />
        <slot name="addName"></slot>
      </div>
      <div class="r-add" @click="showAddAlert" v-if="addShow2">
        <img src="../assets/image/land/addland.png" class="add-icons" />
        <slot name="addName2"></slot>
      </div>
      <div class="process-button" v-if="processShow">
        <div class="button" @click="addProcess">
          <img src="../assets/image/land/addland.png" class="add-icons" />
          <span>新增流程</span>
        </div>
        <div class="button" @click="batchAdd">
          <img
            src="../assets/image/configure/process/icon1.png"
            class="add-icons"
          />
          <span>批量添加</span>
        </div>
      </div>
      <div class="farm-show-slogan" v-if="sloganShow">定制您的农场电子名片</div>
    </div>
  </div>
</template>
<script>
import {
  getHanshanUserListApi,
  getMemberListApi,
  getProductListApi,
  getProductClassListApi,
} from "@/request/api";
import { editTime, editTime2 } from "@/utils/editTime";
import { mapState } from "vuex";
export default {
  props: {
    processShow: {
      type: Boolean,
      default: false,
    },
    sloganShow: {
      type: Boolean,
      default: false,
    },
    addShow: {
      type: Boolean,
      default: true,
    },
    addShow2: {
      type: Boolean,
      default: false,
    },
    tabsShow: {
      type: Boolean,
      default: false,
    },
    tabsShow2: {
      type: Boolean,
      default: false,
    },
    tabsShow3: {
      type: Boolean,
      default: false,
    },
    personnelShow: {
      type: Boolean,
      default: false,
    },
    linkmanShow: {
      type: Boolean,
      default: false,
    },
    taskShow: {
      type: Boolean,
      default: false,
    },
    seedShow: {
      type: Boolean,
      default: false,
    },
    tabList: {
      type: Array,
    },
    tabList2: {
      type: Array,
    },
    task_type: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tabList3: {
      type: Object,
    },
  },
  data() {
    return {
      isFocusShow: true,
      isTaskFocusShow: true,
      seedValue: "",
      linkmanType: "",
      linkmanName: "",
      personnelType: "",
      personnelName: "",
      changeIndex: 0,
      changeIndex2: 0,
      changeIndex3: 0,
      taskName: undefined,
      status: undefined,
      person: undefined,
      land: undefined,
      taskTime: [],
      memberList: [],
      memberTotal: 10,
      memberCurrent_page: 1,
      userNameList: [],
      userNameTotal: 10,
      userNameCurrent_page: 1,
      cropClassList: [],
      cropClassTotal: 10,
      cropClassCurrent_page: 1,
      cropClassId: "",
      cropItemsList: [],
      productTotal: 10,
      productCurrent_page: 1,
    };
  },
  watch: {
    cropClassId() {
      this.cropItemsList = [];
    },
  },
  created() {
    this.changeIndex = sessionStorage.getItem("childTitle") || 0;
    this.getMemberListApi();
    this.getHanshanUserList();
    this.getProductClassListApi();
  },
  computed: {
    ...mapState({
      uploadImgs: (state) => state.app.uploadImgs,
      farmID: (state) => state.user.farmID,
    }),
  },
  methods: {
    async getProductClassListApi(pn = 1) {
      const { code, results } = await getProductClassListApi({
        manor_id: this.farmID,
        pn,
      });
      if (code === 0) {
        this.cropClassList = [...this.cropClassList, ...results.data];
        this.cropClassCurrent_page = results.pn;
        this.cropClassTotal = results.count;
      }
    },
    async changeCropClass(pn = 1, id) {
      this.cropClassId = id;
      this.land = "";
      const { code, results } = await getProductListApi({
        manor_id: this.farmID,
        cid: id,
        pn,
      });
      if (code === 0) {
        this.cropItemsList = [...this.cropItemsList, ...results.data];
        this.productCurrent_page = results.pn;
        this.productTotal = results.count;
      }
    },
    loadMoreCropClassData() {
      if (
        this.cropClassList.length &&
        this.cropClassList.length == this.cropClassTotal
      )
        return;
      this.cropClassCurrent_page++;
      this.getProductClassListApi(this.cropClassCurrent_page);
    },
    loadMoreCropData() {
      if (
        this.cropItemsList.length &&
        this.cropItemsList.length == this.productTotal
      )
        return;
      this.productCurrent_page++;
      this.changeCropClass(this.productCurrent_page, this.cropClassId);
    },
    async getMemberListApi(pn = 1) {
      const { code, results } = await getMemberListApi({
        manor_id: this.farmID,
        pn,
        mtype: 2,
        custom_state: 1,
      });
      if (code === 0) {
        this.memberList = [...this.memberList, ...results.data];
        this.memberTotal = results.count;
        this.memberCurrent_page = results.pn;
      }
    },
    loadMoreMemberData() {
      if (this.memberList.length && this.memberList.length == this.memberTotal)
        return;
      this.memberCurrent_page++;
      this.getMemberListApi(this.memberCurrent_page);
    },
    loadMoreUserNameData() {
      if (
        this.userNameList.length &&
        this.userNameList.length == this.userNameTotal
      )
        return;
      this.userNameCurrent_page++;
      this.getHanshanUserList(this.userNameCurrent_page);
    },
    async getHanshanUserList(pn = 1) {
      const { code, results } = await getHanshanUserListApi({
        manor_id: this.farmID,
        pn,
      });
      if (code === 0) {
        this.userNameList = [...this.userNameList, ...results.data];
        this.userNameTotal = results.count;
        this.userNameCurrent_page = results.pn;
      }
    },
    searchPersonnel() {
      if (!this.personnelType && !this.personnelName) {
        this.isFocusShow = false;
        this.$notify.error({
          title: "错误",
          message: "请选择类型或填写姓名",
        });
      } else {
        this.isFocusShow = true;
        this.$emit("searchPersonnel", {
          name: this.personnelName,
          type: this.personnelType,
        });
      }
    },

    searchLinkman() {
      if (!this.linkmanType && !this.linkmanName) {
        this.isFocusShow = false;
        this.$notify.error({
          title: "错误",
          message: "请选择类型或填写名称",
        });
      } else {
        this.isFocusShow = true;
        this.$emit("searchLinkman", {
          name: this.linkmanName,
          type: this.linkmanType,
        });
      }
    },
    changeCropItems(id) {
      this.$emit("changeCropItems", id);
    },
    addProcess() {
      this.$emit("addProcess");
    },
    batchAdd() {
      this.$emit("batchAdd");
    },
    getTaskSearch() {
      this.taskTime = this.taskTime === null ? [] : this.taskTime;
      this.$emit("getTaskSearch", {
        leader_id: this.person || undefined,
        state: this.status || undefined,

        planning_endtime: editTime(this.taskTime[1]) || undefined,
        planning_starttime: editTime(this.taskTime[0]) || undefined,
      });
    },
    getSeedSearch() {
      this.$emit("getSeedSearch", {
        query_date: editTime2(this.seedValue) || undefined,
        mid: this.person || undefined,
      });
    },
    addLands() {
      this.$emit("addMeans");
    },
    showAddAlert() {
      this.$emit("showAddAlert");
    },
    changeTab(id, index) {
      this.changeIndex = index;
      sessionStorage.setItem("childTitle", this.changeIndex);
      this.$emit("changeTab", id);
    },
    changeTab2(id) {
      this.changeIndex2 = id;
      sessionStorage.setItem("childTitle2", this.changeIndex2);
      this.$emit("changeTab2", id);
    },
    changeTab3(name, id, index) {
      this.changeIndex3 = index;
      sessionStorage.setItem("childTitle3", this.changeIndex3);
      this.$emit("changeTab3", { id, name });
    },
  },
};
</script>

<style lang="less" scoped>
.land-admin {
  width: 100%;
  height: 128px;
  background-color: #0e2138;
  padding: 19px 49px;
  margin: 16px 0;
  position: relative;
  color: #fff;
  .adTitle {
    width: 100%;
    line-height: 22px;
    height: 22px;
    font-size: 22px;
    font-weight: bold;
    color: #fff;
    padding-left: 11px;
    border-left: 4px solid #3e90e5;
    border-radius: 2px;
    margin-bottom: 27px;
  }

  .ad-bot {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .left-cont {
      display: flex;
      align-items: center;

      .m-change {
        color: #fff;
        font-size: 14px;
        display: flex;
        align-items: center;

        p {
          margin-right: 19px;
        }

        /deep/.my-switch {
          width: 40px;
          height: 20px;
        }
      }
    }

    .r-add {
      width: 143px;
      height: 40px;
      background-color: #3e90e5;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 14px;
      cursor: pointer;
      float: right;

      &:hover {
        opacity: 0.8;
      }
      .add-icons {
        width: 18px;
        height: 18px;
        margin-right: 13px;
      }
    }
    .process-button {
      display: flex;

      .button {
        width: 143px;
        height: 40px;
        background-color: #3e90e5;
        border-radius: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-left: 32px;
        font-size: 14px;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }
        .add-icons {
          width: 18px;
          height: 18px;
          margin-right: 13px;
        }
      }
    }

    .tabs {
      border: 1px solid #385576;
      display: flex;
      .tab-items {
        width: 178px;
        height: 42px;
        line-height: 42px;
        text-align: center;
        color: #fff;
        font-size: 16px;
        border-right: 1px solid #385576;
        background-color: #0e2138;
        cursor: pointer;

        &:hover {
          opacity: 0.8;
        }

        &:last-of-type {
          border-right: none;
        }
      }

      .tab-items.active {
        background-color: #3e90e5;
      }
    }

    .personnel,
    .task-box,
    .process-box {
      display: flex;
      align-items: center;
      width: 100%;

      .per-class {
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          margin-right: 20px;
        }

        /deep/.el-select {
          .el-input__inner {
            background-color: #081c30;
            border: 1px solid #385576;
            color: #57708d;
            font-size: 14px;
          }
        }
      }

      .name {
        margin-left: 36px;
        display: flex;
        align-items: center;

        span {
          font-size: 14px;
          margin-right: 20px;
        }

        /deep/.el-input {
          width: 200px;
        }

        /deep/.el-input__inner {
          width: 200px;
          background-color: #081c30;
          border: 1px solid #385576;
          color: #57708d;
          font-size: 14px;
        }
      }

      .searchBtn {
        width: 90px;
        height: 40px;
        background: #3e90e5;
        border-radius: 3px;
        font-size: 14px;
        color: #fff;
        margin-left: 36px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }
      }
    }

    .task-box,
    .process-box {
      display: flex;
      align-items: center;
      width: 100%;


      .searchBtn {
        margin: 0 36px 0 18px;
      }

      .per-class {
        margin: 0 36px 0 0;

        /deep/.el-select {
          width: 155px;
          height: 40px;
        }
      }

      .per-class.task-time {
        /deep/.el-date-editor--datetimerange.el-input,
        /deep/.el-date-editor--datetimerange.el-input__inner {
          width: 300px;
        }

        /deep/.el-input__inner {
          background-color: #081c30;
          border-color: #385576;
        }

        /deep/.el-date-editor .el-range-input {
          background-color: #081c30;
          color: #57708d;
          width: 44%;
        }

        /deep/.el-date-editor .el-range-separator {
          color: #fff;
        }
      }
    }
    .farm-show-slogan {
      color: #fff;
      font-size: 14px;
    }
  }
}

/deep/.personnel-el-input.el-input.active,
/deep/.el-select.personnel-select.active {
  .el-input__inner {
    border-color: #f56c6c !important;
  }
}
</style>
